import React, { useState } from "react";
import { Form, FormControl, useForm } from "@swiftcarrot/react-form";
import ky from "ky";

export default function SubscribeForm() {
  const [confirming, setConfirming] = useState(false);

  function handleSubmit(values, { setSubmitting }) {
    return emailSubscribe(values)
      .then((resp) => {
        setSubmitting(false);
        setConfirming(true);
      })
      .catch((err) => {
        console.error(err);
        alert("订阅失败");
        setSubmitting(false);
      });
  }

  return (
    <div>
      {confirming ? (
        <div className="subscribe-confirming">感谢订阅，请查收确认邮件。</div>
      ) : (
        <Form initialValues={{ email: "" }} onSubmit={handleSubmit}>
          <div className="subscribe">
            <FormControl
              name="email"
              type="email"
              placeholder="请输入邮箱地址"
              className="form-control"
              required
            />
            <FormSubmit />
          </div>
        </Form>
      )}
    </div>
  );
}

function Button({ children, loading, ...props }) {
  return (
    <button className="button button-primary" {...props}>
      {loading ? "..." : "订阅"}
    </button>
  );
}

function FormSubmit({ action, children, component, ...props }) {
  const { submitting, handleSubmit } = useForm();
  const type = action === "submit" ? "submit" : "button";
  const disabled = action === submitting;
  const elProps = { ...props, type, disabled, loading: disabled };

  if (action !== "submit") {
    elProps.onClick = () => handleSubmit(action);
  }

  return React.createElement(component, elProps, children);
}

FormSubmit.defaultProps = {
  action: "submit",
  type: "submit",
  component: Button,
};

function emailSubscribe({ email }) {
  return ky.post("/api/graphql", {
    headers: {
      "Content-Type": "application/json",
    },
    json: {
      query: SEND_EMAIL_TOKEN,
      variables: {
        input: { email },
      },
    },
  });
}

const SEND_EMAIL_TOKEN = /* GraphQL */ `
  mutation($input: SendEmailTokenInput!) {
    sendEmailToken(input: $input) {
      sentAt
    }
  }
`;
